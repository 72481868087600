import formApi from '@utilities/claApiForm';
import clientApi from '@utilities/claApiClient'
import { useQuery, useMutation } from "@tanstack/react-query"
import logger from '@utilities/logService'

const mapResponseToProjectForm = (data) => {
  const {
    ProjectFormID,
    FormName,
    ProjectFormName,
    ProjectID,
    FormDiagnostic,
    Category,
    SignOffUsers,
    ProjectUnitTitles,
    FormProgress,
    ModifiedDate,
    ModifiedUser,
  } = data;
  const projectForm = {
    projectFormID: ProjectFormID,
    formName: FormName,
    projectFormName: ProjectFormName,
    projectID: ProjectID,
    formDiagnostic: FormDiagnostic,
    category: Category,
    signOffUsers: SignOffUsers,
    projectUnitTitles: ProjectUnitTitles,
    formProgress: FormProgress,
    modifiedDate: ModifiedDate,
    modifiedUser: ModifiedUser,
  };
  return projectForm;
};

const mapResponseToProjectFormInstance = (data) => {
  const {
    ProjectFormID,
    FormId,
    ProjectFormName,
    FormDescription,
    ToolTipText,
    FormTypeId,
    Units,
    MethodologyVersionId,
    MethodologyIndustries,
    ProjectFormSchemaID,
    Schema,
    RequiredSignOffLevel,
    IsClientAccessible,
    ClientShareDate,
    ClientDueDate,
    ClientCompletionDate,
    ClientProjectFormStatusId,
    Frameworks,
    PerformanceStandards
  } = data;
  const projectForm = {
    projectFormID: ProjectFormID,
    projectFormName: ProjectFormName,
    formID: FormId,
    formDescription: FormDescription,
    toolTipText: ToolTipText,
    formTypeId: FormTypeId,
    units: Units,
    methodologyVersionId: MethodologyVersionId,
    methodologyIndustries: MethodologyIndustries,
    projectFormSchemaID: ProjectFormSchemaID,
    schema: Schema,
    requiredSignOffLevel: RequiredSignOffLevel,
    isClientAccessible: IsClientAccessible,
    clientShareDate: ClientShareDate,
    clientDueDate: ClientDueDate,
    clientCompletionDate: ClientCompletionDate,
    clientProjectFormStatusId: ClientProjectFormStatusId,
    frameworks: Frameworks,
    performanceStandards: PerformanceStandards
  };
  return projectForm;
};

const duplicateProjectForm = async (projectForm) => {
  try {
    const updatedProjectForm = {
      ProjectId: projectForm.projectId,
      ModifiedUser: projectForm.modifiedUser,
      ModifiedDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
    };

    const result = await formApi.post(
      `projectforms/${projectForm.projectId}/${projectForm.projectFormId}/duplicate`,
      updatedProjectForm
    );
    return { status: 201, data: result.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const renameProjectForm = async (projectId, projectForm) => {
  try {
    const updatedProjectForm = {
      ProjectFormName: projectForm.formNameExtension.trim(),
      ModifiedUser: projectForm.modifiedUser,
      ModifiedDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
    };
    await formApi.put(
      `/projectforms/${projectId}/${projectForm.id}/rename`,
      updatedProjectForm
    );
    return { status: 204 };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getProjectFormWithSchemaById = async (projectFormId, projectId) => {
  try {
    const { data } = await formApi.get(
      `/projectFormWithSchemabyId/${projectId}/${projectFormId}/`
    );
    return mapResponseToProjectFormInstance(data);
  } catch (error) {
    return undefined;
  }
};

const addNewQuestionToProjectFormSchema = async (
  projectId,
  projectFormId,
  sectionId,
  property
) => {
  try {
    const response = await formApi.post(
      `/projectforms/${projectId}/${projectFormId}/schema/${sectionId}/question`,
      property
    );
    return response;
  } catch (error) {
    return undefined;
  }
};

const postProjectFormCustomQuestion = async (
  ProjectFormId,
  QuestionId,
  QuestionLabel,
  QuestionFieldType,
  SectionId,
  SectionName,
  ProjectId
) => {
  try {
    const response = await formApi.post(
      `/projectforms/${ProjectFormId}/customquestions/${ProjectId}`,
      {
        QuestionId,
        QuestionLabel,
        QuestionFieldType,
        SectionId,
        SectionName
      });
    return response;
  } catch (error) {
    return undefined;
  }
};

const putProjectFormCustomQuestion = async (
  ProjectFormId,
  QuestionId,
  QuestionLabel,
  QuestionFieldType,
  SectionId,
  SectionName,
  ProjectId
) => {
  try {
    const response = await formApi.put(
      `/projectforms/${ProjectFormId}/customquestions/${QuestionId}/${ProjectId}`,
      {
        QuestionLabel,
        QuestionFieldType,
        SectionId,
        SectionName
      }
    );
    return response;
  } catch (error) {
    return undefined;
  }
};



const updateQuestionToProjectFormSchema = async (
  projectId,
  projectFormId,
  sectionId,
  rowIndex,
  payload
) => {
  try {
    const response = await formApi.put(
      `/projectforms/${projectId}/${projectFormId}/schema/${sectionId}/question/${rowIndex}`,
      payload
    );
    return response;
  } catch (error) {
    return undefined;
  }
};

const deleteInstanceQuestionById = async (projectId, projectFormId, questionId) => {
  try {
    const { data } = await formApi.delete(
      `/projectforms/${projectId}/projectFormDeleteQuestionById/${projectFormId}/${questionId}`
    );
    const updatedSection = {
      projectFormId: data.ProjectFormId,
      projectFormSchemaId: data.ProjectFormSchemaId,
      section: data.Section,
    };
    return { status: 200, data: updatedSection };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getProjectFormById = async (projectFormId) => {
  try {
    const { data } = await formApi.get(`/projectforms/${projectFormId}/`);
    return mapResponseToProjectForm(data);
  } catch (error) {
    return undefined;
  }
};

const projectFormNameExists = async (projectId, projectForm) => {
  try {
    const form = {
      ProjectId: projectId,
      ProjectFormName: projectForm.formNameExtension.trim(),
      FormName: projectForm.formName.trim()
    };

    const { data } = await formApi.post('/projectforms/name-exists/', form);
    return data?.length > 0;
  } catch (error) {
    return false;
  }
};

const assignProjectFormUnit = async (projectId, projectFormId, units) => {
  try {
    const response = await formApi.put(
      `/projectforms/${projectId}/${projectFormId}/assign-units`,
      units
    );
    return { status: 200, data: response };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};

const createProjectForm = async (projectId, projectForm, units) => {
  try {
    const response = await formApi.post(`/projectforms/${projectId}`, {
      ProjectId: projectForm.projectId,
      FormId: projectForm.formId,
      ProjectFormName: projectForm.projectFormName,
      ProjectDiagnosticId: projectForm.projectDiagnosticId,
      ModifiedDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
      ModifiedUser: projectForm.modifiedUser,
      Units: units
    });
    return { status: 200, data: response };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};

const saveProjectUnitAnswer = async (projectId, projectFormId, unitAnswer) => {
  const newAnswer = {
    ProjectId: unitAnswer.projectId,
    ProjectUnitId: unitAnswer.projectUnitIds,
    QuestionId: unitAnswer.questionId,
    Answer: unitAnswer.answer,
  };
  const response = await formApi.post(`/projectunitanswer/${projectId}/${projectFormId}`, newAnswer);
  return { status: 204, data: response };
};

const deleteProjectUnitAnswer = async (projectId, questionId, units) => {
  try {
    const projectUnitIds = { projectUnitIds: units }
    const response = await formApi.post(`/projectunitanswer/delete/${projectId}/${questionId}`, projectUnitIds);
    return { status: 204, data: response };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const deleteProjectFormById = async (projectId, projectFormId) => {
  try {
    const response = await formApi.delete(`/projectforms/${projectId}/${projectFormId}`);
    return { status: 200, data: response };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getAnswersByProjectId = async (projectFormId, projectId) => {
  try {
    const { data } = await formApi.get(`/allprojectanswers/${projectFormId}/${projectId}`);
    const answerList = data.map((item) => ({
      projectUnitAnswerId: item.ProjectUnitAnswerId,
      projectId: item.ProjectId,
      questionId: item.QuestionId,
      answer: item.Answer,
      lastUpdate: item.LastUpdate?.slice(0, 21).replace('T', ' ')
    }));
    return answerList;
  } catch (error) {
    return undefined;
  }
};

const getRelatedFieldsByQuestionsIds = async (projectId, questionId) => {
  const response = await formApi.get(`/projectforms/${projectId}/getRelatedFieldsByQuestionId/${questionId}`);
  if (response.status === 200) {
    return { data: response.data }
  }
  else {
    return undefined;
  }
}

const getQuestionsWithLink = async (projectId, projectFormId) => {
  const response = await formApi.get(`/projectforms/${projectId}/getallquestionwithlink/${projectFormId}`);

  if (response.status === 200) {
    return response.data;
  }
  else {
    return undefined;
  }
}

const getQuestionImpactOtherForms = async (projectId, projectFormId) => {
  const response = await formApi.get(`/projectforms/${projectId}/getallquestionsimpactothers/${projectFormId}`);

  if (response.status === 200) {
    return response.data;
  }
  else {
    return undefined;
  }
}

const checkProjectHasForms = async (projectId) => {
  try {
    const { data } = await formApi.get(`/projectforms/category-list/${projectId}`);
    if (data && data.length > 0)
      return true;
    else
      return false;
  } catch (error) {
    return false;
  }
};

const applyDeFaulltForms = async (projectId, userId) => {
  try {
    const project = {
      ProjectId: projectId,
      ModifiedUser: userId
    };
    await formApi.post(
      `/projectforms/createDefault/${projectId}`,
      project
    );
    return { status: 201 };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const hasConflictAnswers = async (projectFormId, projectId) => {
  try {
    const { data } = await formApi.get(`/projectforms/schemaConflict/${projectFormId}/${projectId}`);
    return data;
  } catch (error) {
    return undefined;
  }
};

const getSignOffByProjectFormId = async (projectFormId, projectId) => {
  const { status, data } = await formApi.get(`/projectforms/projectFormSignoff/${projectFormId}/${projectId}`);

  return status === 200 ? data.map(signOff => ({
    projectFormSignOffId: signOff.ProjectFormSignOffId,
    projectFormId: signOff.ProjectFormId,
    signOffLevel: signOff.FormSignOffLevelId,
    signOffUser: signOff.SignOffUser,
    signOffDate: signOff.SignOffDate,
    signOffOrder: signOff.SignOffOrder
  })) : undefined;
}

const signOffProjectForm = async (projectId, projectFormId, signOffLevel, userId) => {
  try {
    const signOff = {
      ProjectFormId: projectFormId,
      FormSignOffLevelId: signOffLevel,
      SignOffUser: userId,
      SignOffDate: new Date().toISOString()
    };
    const { data } = await formApi.post(`/projectforms/signoff/${projectId}`, signOff);
    return {
      status: 201, data: {
        projectFormSignOffId: data.ProjectFormSignOffId,
        projectFormId: data.ProjectFormSignOffData.ProjectFormId,
        signOffLevel: data.ProjectFormSignOffData.FormSignOffLevelId,
        signOffUser: data.ProjectFormSignOffData.SignOffUser,
        signOffDate: data.ProjectFormSignOffData.SignOffDate,
        signOffOrder: data.ProjectFormSignOffData.SignOffOrder
      }
    };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};

const removeSignOffProjectFormById = async (projectId, projectFormSignOffId) => {
  try {
    return await formApi.delete(`/projectforms/${projectId}/deleteProjectFormSignoff/${projectFormSignOffId}`);

  } catch (ex) {
    return { status: 400, message: ex };
  }
};

const updateDominantUnit = async (projectId, projectFormId, projectUnitId) => {
  try {
    const body = { ProjectUnitId: projectUnitId };
    const url = `/projectforms/${projectId}/updateDominantUnit/${projectFormId}`;
    await formApi.put(url, body);
  } catch (e) {
    return { status: 400, message: e };
  }
};

const fetchProjectForms = async (projectId) => {
  try {
    const { data } = await formApi.get(`/projectforms/list/${projectId}`)
    return data
  }
  catch (error) {
    logger.error(error)
  }
}

export const useProjectFormRisks = (projectFormId, projectId) => {
  return useQuery(
    ["projectFormRisks", projectFormId],
    async () => {
      const { data } = await formApi.get(`/projectrisk/${projectId}?ProjectFormId=${projectFormId}`)
      return data
    },
    {
      enabled: !!projectFormId,
      select: (risks) => {
        return risks.map((risk) => risk.FormSectionId)
      }
    }
  )
}

const getProjectRisksByProjectFormId = async (ProjectFormId) => {
  const params = { ProjectFormId }
  try {
    const response = await formApi.get('/projectrisk', { params });
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 500, message: error };
  }
};

const getProjectFormProcedures = async (projectFormId, auditAreaId, projectId) => {
  try {
    const response = await formApi.get(`/projectform/${projectFormId}/procedures/${auditAreaId}/${projectId}`)
    return response;
  } catch (error) {
    return { status: 500, message: error };
  }
}

const updateProjectFormProcedureStep = async (projectId, projectFormId, projectFormProcedureStepId, payload) => {
  try {
    const url = `/projectformprocedurestep/${projectId}/${projectFormId}/${projectFormProcedureStepId}`;
    const response = await formApi.put(url, payload);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getAssertionsByMethodologyVersion = async (methodologyVersionId, isEnabledOnly = false) => {
  try {
    const url = `/projectform/assertions/${methodologyVersionId}`;
    const response = await formApi.get(url);
    return { status: 200, data: (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled) };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const putProjectFormSummaryProcedures = async (projectId, projectFormId, auditAreaId, summaryProcedureSteps) => {
  try {
    const response = await formApi.put(`/projectform/${projectId}/${projectFormId}/summaryprocedures/${auditAreaId}`, summaryProcedureSteps)
    return { status: 204, data: response };
  } catch (error) {
    return { status: 500, message: error };
  }
}

const upsertCustomProcedure = async (projectId, payload) => {
  try {
    const url = `/projectform/upsertcustomprocedure/${projectId}`;
    const response = await formApi.put(url, payload);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const deleteCustomProcedureById = async (projectId, customProcedureId) => {
  try {
    const url = `/projectform/${projectId}/deleteCustomProcedureById/${customProcedureId}`;
    const response = await formApi.delete(url);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const updateSharedForm = async (projectId, payload) => {
  const url = `/projects/${projectId}/shared-forms`;
  const response = await clientApi.put(url, payload);
  return response
};

const fetchCopyAnswerUnits = async (projectFormId, projectId) => {
  const response = await formApi.get(`/projectforms/${projectFormId}/copy-answer-units/${projectId}?projectID=${projectId}`);

  return response.data
}

const projectFormServices = {
  createProjectForm,
  duplicateProjectForm,
  renameProjectForm,
  assignProjectFormUnit,
  deleteProjectFormById,
  getProjectFormById,
  fetchProjectForms,
  projectFormNameExists,
  getProjectFormWithSchemaById,
  addNewQuestionToProjectFormSchema,
  updateQuestionToProjectFormSchema,
  saveProjectUnitAnswer,
  deleteProjectUnitAnswer,
  getAnswersByProjectId,
  deleteInstanceQuestionById,
  getRelatedFieldsByQuestionsIds,
  getQuestionsWithLink,
  getQuestionImpactOtherForms,
  checkProjectHasForms,
  applyDeFaulltForms,
  signOffProjectForm,
  getSignOffByProjectFormId,
  hasConflictAnswers,
  removeSignOffProjectFormById,
  updateDominantUnit,
  getProjectRisksByProjectFormId,
  getProjectFormProcedures,
  updateProjectFormProcedureStep,
  getAssertionsByMethodologyVersion,
  putProjectFormSummaryProcedures,
  deleteCustomProcedureById,
  upsertCustomProcedure,
  postProjectFormCustomQuestion,
  putProjectFormCustomQuestion,
  fetchCopyAnswerUnits
};

export default projectFormServices;

export const useProjectFormList = (projectId) => {
  return useQuery(
    ["projectFormList", projectId],
    () => fetchProjectForms(projectId),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  )
}

export const useCopyAnswerUnits = (projectFormId, projectId) => {
  return useQuery(
    ["copyAnswerUnits", projectFormId, projectId],
    () => fetchCopyAnswerUnits(projectFormId, projectId),
    {
      enabled: !!projectFormId && !!projectId
    }
  )
}

export const useProjectFormProcedures = (projectFormId, auditAreaId, projectId) => {
  return useQuery(
    ['projectFormProcedures', auditAreaId],
    () => projectFormServices.getProjectFormProcedures(projectFormId, auditAreaId, projectId),
    {
      enabled: !!auditAreaId && !!projectFormId && !!projectId
    }
  )
}

export const useGetAnswersByProjectFormId = (projectFormId, projectId) => {
  return useQuery(
    ['projectFormAnswers', projectFormId],
    () => projectFormServices.getAnswersByProjectId(projectFormId, projectId),
    {
      enabled: !!projectFormId && !!projectId
    }
  )
}

export const useUpdateSharedForm = () => {
  return useMutation(
    (payload) => {
      return updateSharedForm(payload.projectId, payload.body)
    }
  )
}

export const useCopyAnswerMutation = (projectFormId) => {
  return useMutation(
    (body) => {
      return formApi.post(`/projectforms/${projectFormId}/copy-answers`, {
        "projectId": body.projectId,
        "projectUnitId": body.projectUnitId
      })
    })
}

export const useSaveProjectUnitAnswer = () => {
  return useMutation(
    (payload) => {
      try {
        return saveProjectUnitAnswer(payload.projectId, payload.projectFormId, payload.unitAnswer);
      }
      catch (ex) {
        return { status: 400, message: ex };
      }
    }
  )
}

export const useGetProjectFormWithSchemaById = (projectFormId, projectId) => {
  return useQuery(
    ["useGetProjectFormWithSchemaById", projectFormId],
    () => getProjectFormWithSchemaById(projectFormId, projectId),
    {
      enabled: !!projectFormId && !!projectId
    }
  )
}

export const useGetQuestionsWithLink = (projectId, projectFormId) => {
  return useQuery(
    ['useGetQuestionsWithLink', projectId, projectFormId],
    () => getQuestionsWithLink(projectId, projectFormId),
    {
      enabled: !!projectId && !!projectFormId
    }
  )
}